import React from 'react'

const cardTeam = (props) => {
  const {name, lastName, image, rol, description} = props
  return (
    <div className='item'>
      <img src={image} alt={`${name} ${lastName}`} />
      <h3 className='weight-400'>
        {name} <span className='weight-300'>{lastName}</span>
      </h3>
      <span>{rol}</span>
      <p>{description}</p>
    </div>
  )
}

export default cardTeam
