import React from 'react'
import pictureContact from '../assets/images/picture-contact.svg'
import iconWhatsapp from '../assets/images/whatsapp.svg'
import iconEmail from '../assets/images/email.svg'
import iconCall from '../assets/images/phone.svg'

const Contact = () => {
  return (
    <section className='contact grid'>
      <div className='content-contact'>
        <h2 className='subtitle'>Contáctanos</h2>
        <div className='content-btn'>
          <a
            href='https://api.whatsapp.com/send?phone=573022506547&text=¡Hola%20DyCO Solutions! %20Me%20interesa%20recibir%20información%20de%20sus%20servicios.'
            target='_blank'
            rel='noopener noreferrer'
            className='btn-contact'
          >
            <img src={iconWhatsapp} alt='Icono whatsapp' className='img' />
            Whatsapp
          </a>
          <a
            href='mailto:info@dycosolutions.com'
            target='_blank'
            rel='noopener noreferrer'
            className='btn-contact'
          >
            <img src={iconEmail} alt='Icono email' className='img' />
            Email
          </a>
          <a href='tel:+573022506547' className='btn-contact'>
            <img src={iconCall} alt='Icono llamanos' className='img' />
            Llamanos
          </a>
        </div>
        <div className='data-contact'>
          <h3>Datos de contacto</h3>
          <div className='info'>
            <p>
              <span className='weight-700'>Movil:</span> (+57) 302 250 6547
            </p>
            <p>
              <span className='weight-700'>Email:</span> info@dycosolutions.com
            </p>
          </div>
        </div>
      </div>
      <div className='img-contact'>
        <img
          src={pictureContact}
          alt='Imagen acerca de nosotros'
          className='img'
        />
      </div>
    </section>
  )
}

export default Contact
