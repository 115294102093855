import React from 'react'
import pictureHeader from '../assets/images/picture-header.svg'
import pictureAboutUs from '../assets/images/picture-about-us.svg'
import Navbar from './Navbar'
import Contact from './Contact'
import CardTeam from './cardTeam'
import jfelizzolat from '../assets/images/jeffrey-felizzola.png'
import lcontreras from '../assets/images/luis-contreras.jpg'
import kcantillo from '../assets/images/kevin-cantillo.jpg'
import sguevara from '../assets/images/sergio-guevara.jpg'
import bgCircle from '../assets/images/background-circle.svg'

const landing = () => {
  return (
    <>
      <header className='header'>
        <Navbar />
        <div className='background-circle'>
          <img src={bgCircle} alt='' className='circle-lg' />
          <img src={bgCircle} alt='' className='circle-sm' />
        </div>
        <div className='grid'>
          <div className='header-texts'>
            <h1 className='weight-700'>
              Innovando y adaptando tecnologías{' '}
              <span className='weight-400'>
                al servicio de Colombia y el mundo.
              </span>
            </h1>
            <p className='paragraph'>
              Desarrollo de software y sistemas electrónicos, los cuales son
              elaborados para cumplir las necesidades especificas y globales de
              nuestros clientes.
            </p>
            <a
              href='https://api.whatsapp.com/send?phone=573022506547&text=¡Hola%20DyCO Solutions! %20Me%20interesa%20recibir%20información%20de%20sus%20servicios.'
              target='_blank'
              rel='noopener noreferrer'
              className='btn-call-to-action'
            >
              Contáctanos
            </a>
          </div>
          <div className='img-header'>
            <img src={pictureHeader} alt='Imagen principal' className='img' />
          </div>
        </div>
      </header>
      <main>
        <section className='about-us grid'>
          <div className='text-about-us'>
            <h2 className='subtitle weight-700'>
              DyCO <span className='weight-400'>Solutions</span>
            </h2>
            <p className='paragraph'>
              En <span className='weight-400'>DyCO Solutions</span> estamos
              convencidos de que el éxito de nuestra empresa se sustenta
              principalmente en tres pilares fundamentales, como lo son:
              Nuestros clientes, nuestros empleados y nuestras novedades
              tecnológicas.
            </p>
            <p className='paragraph'>
              Nos preocupamos por realizar nuestro trabajo bajo los mejores
              estándares de innovación y calidad, para así poder brindar a
              nuestros clientes una experiencia óptima de eficiencia e
              innovación en cada proceso, facilitando así su crecimiento en sus
              proyectos y solucionando las necesidades y objetivos en cada uno
              de ellos.
            </p>
          </div>
          <div className='img-about-us'>
            <img
              src={pictureAboutUs}
              alt='Imagen acerca de nosotros'
              className='img'
            />
          </div>
        </section>
        <section className='our-team'>
          <section className='grid'>
            <h2 className='subtitle weight-700'>
              Nuestro <span className='weight-400'>equipo</span>
            </h2>
            <div className='items'>
              <CardTeam
                name='Kevin'
                lastName='Cantillo'
                image={kcantillo}
                rol='Desarrollador FullStack'
                description='Desarrollador FullStack, apasionado por la electrónica y energías autosustenibles.'
              />
              <CardTeam
                name='Luis'
                lastName='Contreras'
                image={lcontreras}
                rol='Electrónico'
                description='Ingeniero de sistemas, Docente y con una alta experiencia en electrónica.'
              />
              <CardTeam
                name='Jeffrey'
                lastName='Felizzola'
                image={jfelizzolat}
                rol='Desarrollador Frontend'
                description='Ingeniero de sistemas dedicado al diseño y desarrollo de aplicaciones web.'
              />
              <CardTeam
                name='Sergio'
                lastName='Guevara'
                image={sguevara}
                rol='Contador'
                description='Contador público, con excelente desempeño en el ámbito económico y financiero.'
              />
            </div>
          </section>
        </section>
      </main>
      <Contact />
      <footer className='footer'>
        ¡Hecho con <div className='heart-shape'></div> de Colombia para el
        mundo!
      </footer>
    </>
  )
}

export default landing
