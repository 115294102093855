import React, {useEffect} from 'react'
import logoDyco from '../assets/images/logo-dyco-solutions.svg'

const Navbar = () => {
  const scrollFunction = () => {
    const scrollNavbar = document.getElementById('scroll-navbar')
    document.documentElement.scrollTop > 50
      ? scrollNavbar.classList.add('background-navbar')
      : scrollNavbar.classList.remove('background-navbar')
  }
  useEffect(() => {
    window.onscroll = () => {
      scrollFunction()
    }
  }, [])
  return (
    <section id='scroll-navbar' className='content-navbar'>
      <nav className='navbar'>
        <img src={logoDyco} alt='Logotipo DyCO Solutions' className='brand' />
        <a
          href='https://api.whatsapp.com/send?phone=573022506547&text=¡Hola%20DyCO Solutions! %20Me%20interesa%20recibir%20información%20de%20sus%20servicios.'
          target='_blank'
          rel='noopener noreferrer'
          className='btn-call-to-action'
        >
          Contáctanos
        </a>
      </nav>
    </section>
  )
}

export default Navbar
